.header-xs {
  font-size: 16px !important;
  color: #000000d9;
}
.paragraph-xs {
  font-size: 14px;
}
.text-h {
  margin-top: 8px;
}
.text-massage-name {
  font-weight: bolder !important;
  font-size: 16px !important;
  color: #000000d9;
}
.font-size-40 {
  font-size: 40px !important;
  color: #d9d9da !important;
}

.font-size-250 {
  font-size: 250px !important;
  color: #d9d9da !important;
}
