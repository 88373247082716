//////////////////// container ///////////////////
.header {
  position: fixed;
  top: 0;
  right: 0px;
  z-index: 1;
  height: 64px !important;
  width: 99.2vw;
  color: #fff;
  background-image: url("../../assets/img/header-curve.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.row-h {
  height: 64px !important;
}

.brand-name {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  font-size: 18px;
  letter-spacing: 5px;
}

.card-table-tab {
  height: 120%;
  background: white;
  padding-top: 8px;
  margin-left: 1px;
  border-radius: 0px 8px 8px 8px;
  box-shadow: 0 6px 6px -4px #cacaca;
}

.card-table-notab {
  padding-top: 8px;
  height: 120%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 6px 6px -4px #cacaca;
}

.card-footer {
  background: blueviolet;
  position: absolute;
  width: 100%;
}

.img-squad {
  border-radius: 4px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.img-squad-detail {
  margin-top: 10px;
  border-radius: 4px;
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.img-round {
  border-radius: 4px;
}
.img-upload-squad {
  border-radius: 4px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.img-upload-padding {
  padding: 4px;
}

.img-cover {
  object-fit: cover;
}
.second-header {
  width: 100% !important;
  background: white !important;
  height: 60px;
  margin-bottom: 18px;
  padding: 10px 25px 10px 25px;
  box-shadow: 0 6px 6px -4px #cacaca;
  position: fixed;
  z-index: 1;
}
.second-header {
  padding: 10px 25px 10px 226px;
  transition: all 0.2s;
}

.flex-layout .second-header {
  padding: 10px 25px 10px 106px;
  transition: all 0.2s;
}
.set-layout {
  padding: 76px 24px 0px 224px;
  min-height: 360px;
  transition: all 0.2s;
}
.flex-layout .set-layout {
  padding: 76px 24px 0px 104px;
  min-height: 360px;
  transition: all 0.2s;
}

.text-head {
  font-size: 16px;
  font-weight: 600;
}
.text-h {
  font-size: 14px;
  font-weight: 600;
}

.icons-size {
  font-size: 12px;
}
.img-size {
  max-width: 50rem;
}
.site-layout-background {
  position: absolute;
  top: 50%;
  left: 50%;
}
.btn-create {
  width: 100%;
}

////////////////////media space////////////////////
.media-space-container {
  // margin-top: 8px;
  position: relative;
  padding: 0 15px;
  width: 100%;
  min-height: 300px;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.media-space-container-marker {
  // margin-top: 8px;
  position: relative;
  padding: 0 0px;
  width: 100%;
  min-height: 300px;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  // cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plus-btn {
  width: 115px;
  height: 35px;
  cursor: pointer;
  position: relative;
  padding: 0 0px;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  // cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cursor {
  cursor: pointer !important;
}
.media-icon-block {
  border-radius: 4px;
  background: white;
  padding: 8px;
}

.icon-media-format {
  display: none;
}
.media-focus {
  position: relative;
}

.media-focus:hover .icon-media-format {
  display: unset !important;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.media-focus:hover .blur {
  filter: blur(1px);
}

////////////////////text color////////////////////
.c-green {
  color: $green_color;
}
.c-grey {
  color: #ccc;
}

///////////////////Upload Photo/////////////////
.imgUploadPhoto {
  border: solid 1px #d9d9d9;
  height: 320px;
  width: 100%;
  border-radius: 4px;
  font-size: 320px;
}

////////////////////Row drage////////////////////

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
////////////////////SortList////////////////////
.main-menu {
  list-style: none !important;
}
.main-menu {
  .mom {
    margin-top: 0.5rem;
    height: 30px;
    background: $primay_color;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .childx {
    margin-left: 2rem;
    margin-top: 0.5rem;
    height: 30px;
    background: blue;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/////////////////border color////////////////////
.p-border {
  color: #d9d9d9;
}

////////////////style icon social//////////////////

.icon-social {
  display: flex;
  margin: auto 0;
  margin-top: 5px;
}

.icon-social span {
  margin-left: 10px;
  margin-top: 2px;
}

.border-social {
  border-radius: 5px;
  border: 1px solid #d9d9da;
  background: #f9f9f9 0% 0% no-repeat padding-box;
}

////////////////approve style/////////////////
.approve-status {
  background-color: #16baff;
  width: 130px;
  height: 39px;
  margin: 0 auto;
  color: white;
  opacity: 1;
  border-radius: 5px;
  padding-top: 8px;
}

.pending-status {
  background-color: #d9d9da;
  width: 130px;
  height: 39px;
  margin: 0 auto;
  color: white;
  opacity: 1;
  border-radius: 5px;
  padding-top: 8px;
  display: flex;
  justify-content: center;
}

.Image {
  margin-top: 2.5px;
}

.circle {
  border-radius: 50% !important;
}
.avatar {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.editor-button {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.editor-process {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.editor-icon > .avatar {
  padding-bottom: 7px;
}
.buttonBlackHome {
  width: 20px !important;
  height: 20px !important;
  object-fit: cover;
  padding-bottom: 3.5px;
}

// .ant-menu-horizontal {
//   border-bottom: unset !important;
//   line-height: 0px !important;
// }

.ant-dropdown-menu {
  margin-top: 13px !important;
  background: $primay_color !important;
  border-radius: 8px !important;
}
span.ant-dropdown-menu-title-content {
  color: whitesmoke;
}
span.ant-dropdown-menu-title-content:hover {
  color: white !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: unset !important;
}
.hr-verticle {
  border-left: 1px solid white;
  height: 18px;
}

.list-menu {
  color: whitesmoke;
}
.list-menu:hover {
  color: white;
}

.menu-hover:hover .menu-list {
  color: white !important;
}

.input-colors {
  border: 1px solid #ececec;
  height: 40px;
  padding: 4px 0px 4px 11px;
  color: #ced4da;
  border-radius: 4px;
}

.login-layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-boxs {
  width: 27vw;
}

.login-input.ant-input,
.ant-input-password.login-input {
  height: 50px !important;
  border-radius: 20px !important;
  border: unset !important;
  padding: 12px 20px 12px 20px;
  text-align: center;
  font-size: 15px;
}
.login-input input.ant-input {
  height: 40px !important;
}

.ant-input-password input {
  text-align: center;
  font-size: 15px;
}

.login-input .ant-input-prefix {
  padding: 10px !important;
}
.login-input.ant-input-affix-wrapper {
  height: 50px !important;

  border-radius: 20px !important;
  border: 0px solid transparent !important;
}
.login-input .ant-input {
  margin-top: 5px !important;
  width: 83%;
  text-align: center !important;
}
.login-input.ant-input-password > .ant-input {
  margin-top: 5px !important;
}

.login-button.ant-btn {
  padding: 0px 60px !important;
  border-radius: 20px !important;
  height: 50px !important;
  color: white !important;
  background-color: $primay_color !important;
}

.login-background {
  background-image: url("../img/login-background.jpg");
}

.image-render {
  width: 100% !important;
  height: 500px !important;
  object-fit: contain;
}
.image-render-uploadphoto {
  width: 15vw !important;
}

.image-render-Marker {
  width: 100% !important;
  height: 720px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.icon-warning-del {
  color: red !important;
  padding-right: 0.5rem;
}

.circle-shape {
  width: 22px;
  height: 22px;
  background: black;
  color: white !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.squad-shape {
  height: 40px !important;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.squad-shape:hover {
  border-color: $primay_color;
}

.list-image-upload {
  height: 40px !important;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px !important;
}

.ant-btn.btn-delete-upload {
  border: unset !important;
  height: 37px !important;
}
.layout-formitem-project .ant-col.ant-form-item-label {
  padding: 0 0 3px;
}

.layout-col-project {
  margin-bottom: 10px;
}

.list {
  height: 35px;
  cursor: pointer !important;
}
.active-list {
  background-color: #f6f6f6;
}

.list:hover {
  background-color: #f6f6f6;
}

.action-btn {
  cursor: pointer;
}

.icon-edit {
  width: 100%;
  height: 190px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background-color: rgb(249, 249, 249);
  border-radius: 5px;
  cursor: pointer;
}

.icon-edit-fix {
  width: 100%;
  height: 190px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background-color: rgb(249, 249, 249);
  border-radius: 5px;
  cursor: pointer;
}

.editer-custom-layout .ant-form-item-label {
  font-weight: bold;
  padding: 10px 0px 0px 0px !important;
  margin-top: 8px;
}

.editer-custom-layout .ant-form-item-explain.ant-form-item-explain-error {
  padding: 10px 0px 0px 0px !important;
}

.button-save-modol.ant-btn {
  color: #fff !important;
  background: #ffc627 !important;
  border-color: #ffc627 !important;
}
.button-save-modol.ant-btn:focus {
  color: #ffc627 !important;
  background: #fff !important;
  border-color: #ffc627 !important;
}

.text-description-custom {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-true-check {
  padding: 5px;
  background-color: orange;
  width: 33px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  z-index: 20;
  right: 20px;
}
////////////////////////preview - article
.preview-content {
  margin-top: 1.5px;
  border: 1px solid white;
  border-radius: 0 4px 4px 4px;
  background: white;
  img {
    width: 100%;
  }
}

.preview-title {
  font-size: 2vw !important;
  font-weight: bold;
  color: #e24301;
}

.preview-description {
  h1 {
    font-size: 1.75vw !important;
  }
  h2 {
    font-size: 1.5vw !important;
  }
  h3 {
    font-size: 1.25vw !important;
  }
}

.preview-image {
  font-size: 1.25vw;
  font-weight: bolder;
}
/////////////preciew - inspiration
.preview-title-ins {
  font-size: 1.5vw !important;
  font-weight: bold;
}

.preview-category {
  font-size: 1.25vw !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

.preview-content-ins {
  font-size: 1.2vw !important;
}

.preview-title-ititle {
  font-size: 20px !important;
  font-weight: bold;
}

.preview-content-text {
  font-size: 16px !important;
}

.preview-topic {
  font-size: 1.2vw;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

.preview-content-project {
  h1 {
    font-size: 1.75vw !important;
  }
  h2 {
    font-size: 1.5vw !important;
  }
  h3 {
    font-size: 1.25vw !important;
  }
  font-size: 0.75vw !important;
}

.preview-title {
  font-size: 2vw !important;
  font-weight: bolder;
  color: #e24301;
}

.preview-title-project {
  font-size: 1.3vw !important;
  font-weight: lighter;
  color: #e24301;
}

.obj-f-cover {
  object-fit: cover !important;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.container1:hover .image {
  opacity: 0.25;
}

.container1:hover .middle {
  opacity: 1;
}
.container1 {
  position: relative;
  width: 50%;
  margin-top: 0.5rem;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

//-------------cut-text-------------//

.card-img-cs {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 300px;
  height: 200px;
  // border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  // box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  transition: box-shadow 0.56s ease-in-out;
}

.cut-text-1-row {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cut-text-2-row {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.avatar-approve {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
}
.border-box {
  border: 1px solid #e4e4e4;
  padding: 20px;
}

.item-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img-product-image {
  height: 280px;
  width: 250px;
  object-fit: cover;
}

.price {
  font-size: 24px;
  color: #ff6b0b;
  font-weight: bold;
}

.amount-box {
  padding: 0px 7px;
  background: #f7f7f7;
  margin-top: 3px;
  border-radius: 5px;
}

.text-product-list {
  color: #545454 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.image-container {
  position: relative;
  width: 100%;
}

.image-preview {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 10px;
}

.text-container {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.text-container {
  background-color: rgba(255, 255, 255, 0.5);
  color: #ff6b0b;
  border-radius: 50%;
  padding: 6px 8px;
}

.image-container:hover .image-preview {
  opacity: 0.7;
  border-radius: 10px;
}

.image-container:hover .text-container {
  opacity: 1;
}

.card-box-v3:hover .title-card-box {
  color: #f97b2c;
}

.card-box-v3 {
  width: 100%;
  max-width: 500px;
  transition: 0.5s ease;
  border-radius: 7px;
  background-color: white;
}

.card-box-v3:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.image-card-box-v3 {
  object-fit: cover !important;
  transition: 0.5s ease;
}

.card-box-v3.h-100.cursor-pointer div {
  border-radius: 7px 7px 0px 0px;
}

.relative {
  position: relative !important;
}

.bg-gray-100 {
  background-color: rgb(243, 244, 246) !important;
}

.text-box-inline {
  text-align: left;
  font-size: 11px;
  color: #808284;
}

.title-detail-article {
  color: #f97b2c;
  font-size: 25px;
  font-weight: 700;
}

.text-detail-article {
  color: #777777;
  font-size: 14px;
}

.des-detail-article {
  color: #777777;
  font-size: 15px;
}
.des-detail-article {
  color: #777777;
  font-size: 15px;
}
.des-detail-article img {
  width: 100%;
}

.text-image-box {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  color: white;
  background-color: #f97b2c;
  padding: 3px 15px;
  font-size: 13px;
}

.title-card-box {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  text-align: left;
  font-weight: 700;
  height: 25px; /// add new
  color: #303030;
  transition: 0.3s ease;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.border {
  border: 1px solid #d9d9d9 !important;
}

.border-fade-html-tag {
  border-bottom: 50px solid rgba(0, 0, 0, 0.3);
  opacity: 0.9;
  margin-top: -50px;
}

.btn-more {
  background-color: #fff;
  color: #f97b2c;
  border: 1px solid #f97b2c;
  border-radius: 3px;
  width: 150px;
  text-align: center;
  padding: 8px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s ease-out;
}

.btn-more:hover {
  background-color: #f97b2c;
  color: #fff;
  border: 1px solid #fff;
}
.color-box {
  width: 25px;
  height: 25px;
}

.box-round {
  border-radius: 3px;
}