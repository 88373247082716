.c-black {
  color: black !important;
}
.c-white {
  color: white !important;
}
.c-whitesmoke {
  color: whitesmoke !important;
}

.c-orange {
  color: #e24301 !important;
}
.c-orange-light {
  color: #f97b2c !important;
}
