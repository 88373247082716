.dnd-list__draggable {
  position: relative;
  /* z-index: 1973; */
}

/* .dnd-list__in-drag {
  z-index: 1978;
} */

.dnd-list__transition {
  transition: transform 0.3s;
}
