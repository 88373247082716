.main-card-group {
  margin: 10px;
}
.main-card-group-item {
  margin-bottom: 5px;
}
.collapse-card-group {
  width: 100%;
  display: flex;
  align-items: center;
}
.drag-card-group {
  width: 50px;
  background-color: #fff;
  text-align: center;
  cursor: all-scroll;
}
