.ProseMirror {
  padding: 10px 10px 10px 10px;
  min-height: 25vh;

  > * + * {
    margin-top: 0.75em;
  }
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
  }

  ul,
  ol {
    padding: 0 1rem;
  }
  // h1 h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   line-height: 1.1;
  // }

  h1 {
    font-size: 1.75em;
  }
  // h2 {
  //   font-size: 23px;
  // }
  // h3 {
  //   font-size: 18px;
  // }
  // h4 {
  //   font-size: 13px;
  // }
  // h5 {
  //   font-size: 8px;
  // }
  // h6 {
  //   font-size: 3px;
  // }

  strong {
    font-family: "Sukhumvit-Bold" !important;
  }
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.tippy-content {
  background: white;
  border-radius: 5px;
  padding: 3px;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
}
.editor-frame {
  // border: 1px solid gray;
  border-radius: 5px;
  // box-shadow: 6px 6px 6px -4px rgb(218, 191, 191);
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  margin-top: 8px;
}
.editor-frame:hover {
  border-color: #ffd64f !important;
}

.editor-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  background-color: #f9f9f9;
}
.header-tap {
  height: 8px;
  border-radius: 5px 5px 0px 0px;
  background: black;
}

input.t-btn {
  //border: 1px solid red !important;
  width: 35px;
  height: 29px !important;
  padding: 1px 2px;
  //object-fit: cover;
  cursor: pointer;
  bottom: -5px;
  // background: white !important;
}
.t-select .ant-select-selector {
  height: 30px !important;
  padding: 1px;
}
.ml-1px {
  margin-left: 3px !important;
}
// .t-select .ant-select {
//   // width: 35px !important;
//   height: 30px !important;
// }

button.t-btn {
  border: 0px !important;
  // width: 40px;
  // padding: 8px;

  cursor: pointer;
  // background: white !important;
}
.t-btn:hover,
.t-btn-buble:hover {
  background: whitesmoke !important;
  color: white !important;
  // border-radius: 5px;
}

.ProseMirror:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.t-btn.ant-btn {
  box-shadow: unset !important;
  padding: 1px !important;
  border: 1px solid #d9d9da !important;
  color: black !important;
  margin: 3px 2px;
  border-radius: unset !important;
  height: auto !important;
}
.t-color-btn.ant-btn {
  padding: 1px !important;
  border: 1px solid #d9d9da !important;
  color: black !important;
  margin-left: 3px;
  border-radius: unset !important;
  height: auto !important;
}

.t-btn-buble.ant-btn {
  margin: 1px 1px;
  height: unset !important;
  box-shadow: unset !important;
  padding: 1px !important;
  border: 1px solid #d9d9d9 !important;
  color: black !important;
  border-radius: unset !important;
}

////////////////////////////Menu Active//////////////////////////////
.left.is-active,
.right.is-active,
.center.is-active,
.bold.is-active,
.italic.is-active,
.underline.is-active,
.link.is-active,
.h1.is-active,
.h2.is-active,
.h3.is-active,
.h4.is-active,
.h5.is-active,
.h6.is-active,
.bullet.is-active,
.orderlist.is-active,
.blockquote.is-active,
.horizon.is-active,
.undo.is-active,
.color.is-active,
.redo.is-active {
  background: #d9d9d9 !important;
}

////////////////////////////Color panton//////////////////////////////
.tt-color-orange-red {
  background-color: #e24301 !important;
  width: 30px;
}

.tt-color-orange {
  background-color: #ff6b0b !important;
  width: 30px;
}
.tt-color-yellow {
  background-color: #ffc627 !important;
  width: 30px;
}
.tt-color-black {
  background-color: #231f20 !important;
  width: 30px;
}
.tt-color-cool-gray {
  background-color: #808284 !important;
  width: 30px;
}
