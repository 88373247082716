.p-1 {
  padding: 1rem !important;
}
.p-15 {
  padding: 1.5rem !important;
}
.p-2 {
  padding: 2rem !important;
}
.p-3 {
  padding: 3rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.p-5 {
  padding: 5rem !important;
}
.pt-05 {
  padding-top: 0.5rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pt-2 {
  padding-top: 2rem !important;
}
.pt-3 {
  padding-top: 3rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.mt-05 {
  margin-top: 0.5rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-05 {
  margin-left: 0.5rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-3-5 {
  margin-left: 3.5rem !important;
}
.ml-6 {
  margin-left: 6rem !important;
}
.ml-9-5 {
  margin-left: 9.5rem !important;
}
.ml-10 {
  margin-left: 10rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}
.mr-9-5 {
  margin-right: 9.5rem !important;
}
.mx-3 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pl-15 {
  padding-left: 1.5rem !important;
}
.pl-9-5 {
  padding-left: 9.5rem !important;
}
.pl-2 {
  padding-left: 2rem !important;
}
.pr-05 {
  padding-right: 0.5rem !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pr-15 {
  padding-right: 1.5rem !important;
}
.pr-2 {
  padding-right: 2rem !important;
}
.pr-3 {
  padding-right: 3rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-40 {
  width: 40% !important;
}
.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-60 {
  width: 60% !important;
}
.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}
.w-50px {
  width: 50px !important;
}

.h-100 {
  height: 100% !important;
}

.h-80vh {
  height: 80vh !important;
}
.h-auto {
  height: auto !important;
}
.h-12rem {
  height: 12rem !important;
}

.w-16rem {
  width: 16rem !important;
}

.media-img {
  height: 10rem;
}
@media only screen and (min-width: 1900px) {
  .media-img {
    height: 15rem !important;
  }
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.p-24 {
  padding: 24px !important;
}

.text-aling-center {
  text-align: center;
}

.left-0 {
  left: 0px !important;
}

.bottom-0-5 {
  bottom: 0.125rem !important;
}


.relative {
  position: relative !important;
}


.px-3 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.my-3 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.p-0 {
  padding: 0px !important;
}

.my-4 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.bg-gray-fa {
  background-color: #fafafa;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}