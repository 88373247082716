.d-flex {
  display: flex !important;
}
.d-none {
  display: none !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-flex-end {
  justify-content: flex-end !important;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-around {
  justify-content: space-around !important;
}
.direction-col {
  flex-direction: column;
}
.items-center {
  align-items: center !important;
}
.items-end {
  align-items: flex-end !important;
}
.text-center {
  text-align: center !important;
}
.p-absolute {
  position: absolute !important;
}

.flex-warp {
  flex-wrap: wrap !important;
}
.flex-column {
  flex-direction: column !important;
}
