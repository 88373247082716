.main-card-list {
  margin: 10px;
  list-style: none;
  padding: 5px 0;
}
.main-card-list li {
  padding: 5px 0;
}

.main-card-list .el {
  background: #fafafa;

  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  padding: 0 30px;
  border-radius: 5px;
  height: 66px;

  transition: background-color 0.3s;
  cursor: pointer;
}
.main-card-list .active {
  /* background: #fff; */
  color: #000;
}

@media screen and (max-width: 740px) {
  .main-card-list {
    padding-bottom: 0;
  }

  .main-card-list li {
    padding-bottom: 5px;
  }
}
