.leaflet-container {
  width: 100%;
  height: 100%;
  background: transparent;
}

.l-icon {
  background-color: transparent;
  border: none;
}

// .dashboard-container {
//   height: calc(100% - #{$height-navaigation});
// }

.tooltip {
  padding: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #222;
}

.ant-btn-dangerous.ant-btn-primary.btn-map {
  border-radius: 20% !important;
}

// .image-bg-map {
//   height: 90vh;
//   width: auto;
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
// }

.card-color-pick {
  border-radius: 3px;
  border: 1px solid rgba(207, 207, 207, 0.719);
  display: inline-block;
  cursor: pointer;
  // margin-left: 20px;
}

.color-picker-show {
  width: 50px;
  height: 25px;
  border-radius: 2px;
}

// .my-custom-pin {
//   z-index: 0 !important;
// }

.set-layout-map {
  padding: 20px 24px 20px 224px;
  min-height: 360px;
  transition: all 0.2s;
}

// .card-btn-marker-popup {
//   background: #f9f9f9;
//   padding: 10px 15px;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 10px;
// }

.tab-pick-color {
  background-color: rgb(56, 56, 56);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: normal;
}

button.ant-btn.btn-add-marker {
  height: 30px !important;
  width: 110px !important;
}

button.ant-btn.btn-change-image {
  height: 30px !important;
  background-color: #ff6b0b;
}

button.ant-btn.btn-change-image:hover {
  background-color: #fc944f;
}

// .popup-diy {
//   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
//     rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
// }

// .leaflet-container {
//   overflow: unset !important;
// }
// .leaflet-pane.leaflet-map-pane {
//   transform: unset !important;
// }
// .leaflet-popup.popup-diy.leaflet-zoom-animated {
//   top: -220px !important;
//   opacity: 1;
//   transform: translate3d(735px, 351px, 0px);
//   bottom: 0px !important;
//   left: -156px !important;
//   height: 208px;
//   width: 401px;
//   border-radius: 10px;
// }

.alert-empty-marker {
  background: #ffc627;
  box-shadow: 0 6px 6px -4px grey;
  padding: 5px 5px;
  border-radius: 5px;
  color: white;
  position: absolute;
  bottom: -4px;
  width: 160px;
  left: -60px;
}

.alert-empty-marker-detail {
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 5px 5px;
  border-radius: 5px;
  color: black;
  position: absolute;
  bottom: 10px;
  left: -50px;
  width: 135px;
  text-align: center;
}

// .alert-empty-marker-preview {
//   background-color: white;
//   padding: 15px 15px;
//   border-radius: 5px;
//   color: black;
//   position: absolute;
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   // bottom: 10px;
//   // left: -50px;
//   left: -130px;
//   width: 300px;
//   text-align: center;
// }

// .alert-empty-marker-preview-ins {
//   background-color: white;
//   padding: 15px 15px;
//   border-radius: 5px;
//   color: black;
//   position: absolute;
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   // bottom: 25px;
//   // left: -50px;
//   // left: -130px;
//   width: 300px;
//   text-align: center;
// }

.alert-empty-marker-item {
  border-bottom: 1px solid lightgray;
}

.alert-marker-cursur {
  cursor: pointer;
  padding: 3px;
}

.alert-marker-cursur:hover {
  background-color: #d9d9da;
}

// .marker-custom {
//   width: 1.2rem;
//   height: 1.2rem;
//   display: block;
//   border-radius: 50%;
//   transform: rotate(45deg);
//   cursor: pointer;
// }

.title-tag {
  background-color: #fdb714 !important;
  border-color: #fdb714 !important;
  color: white;
  transition: 0.5s ease-out;
  padding: 4px 15px;
  border-radius: 3px;
  font-size: 12px;
}

.card-picker-color {
  padding: auto;
}
///////////////////////////////////////////////////////////Marker //////////////////////////////@at-root
//

.card-product-list-pre {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px 5px;
}
.alert-empty-marker-preview {
  background-color: white;
  padding: 15px 15px;
  border-radius: 5px;
  color: black;
  position: absolute;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // bottom: 10px;
  // left: -50px;
  left: -130px;
  width: 300px;
  text-align: center;
}

.marker-custom {
  width: 1.2rem;
  height: 1.2rem;
  // display: block;
  display: flex;
  align-items: center;
  border-radius: 50%;
  // transform: rotate(45deg);
  color: white;
  justify-content: center;
  cursor: pointer;
}

.custom-marker {
  z-index: 1;
  position: relative;
}

.pv-width-default {
  width: 350px;
}
.pv-width-mobile {
  width: 45vw;
}

.alert-empty-marker-preview-ins {
  background-color: white;
  padding: 5px 5px 5px 8px;
  background-color: white;
  border-radius: 5px;
  color: black;
  width: 300px;
  position: absolute;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 2; //origin = 1
  border: 1px solid #f0f2f5;
  transition: opacity 0.2s ease-out, transform 0.6s ease-out;
}

.detail--marker .alert-empty-marker-preview-ins {
  opacity: 0;
  visibility: hidden;
  transition-delay: 0s;
}

.detail--marker-click .alert-empty-marker-preview-ins {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.detail--marker-click-close .alert-empty-marker-preview-ins {
  opacity: 0;
  visibility: hidden;
  transition-delay: 0s;
}

.detail--marker-click .marker-line {
  transition-delay: 0.1s;
  height: 60px;
}
.detail--marker-click .marker-line-horizontal {
  transition-delay: 0.3s;
  width: 60px;
}

.detail--marker-click-close .marker-line {
  transition-delay: 0.3s;
  height: 0px;
}
.detail--marker-click-close .marker-line-horizontal {
  transition-delay: 0s;
  width: 0px;
}

.detail--marker:hover .alert-empty-marker-preview-ins {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.detail--marker .marker-line {
  height: 0px;
  transition-delay: 0.3s; //end
}
.detail--marker .marker-line-horizontal {
  width: 0px; //end
}

.detail--marker:hover .marker-line {
  height: 60px;
  transition-delay: 0s; //START
}
.detail--marker:hover .marker-line-horizontal {
  transition-delay: 0.3s; //START
  width: 60px;
}

.marker-line {
  border-left: 5px solid #ff6b0b;
  height: 0px;
  position: absolute;
  transition: height 0.3s ease;
}
.marker-line-horizontal {
  border-bottom: 5px solid #ff6b0b;
  width: 0px;
  position: absolute;
  transition: width 0.3s ease;
  // transition-delay: 1s;
}

.icon-vertical {
  width: 2.5px;
  height: 13px;
  left: -1.7px;
  border-right: 2px solid white;
  top: -6.5px;
  position: absolute;
  transition: height 0.5s ease;
  visibility: visible;
}

.icon-Horizontal {
  width: 13px;
  height: 2.5px;
  left: -6.5px;
  border-bottom: 2px solid white;
  top: -2px;
  position: absolute;
  transition: width 0.5s ease;
  visibility: visible;
}

.detail--marker:hover .icon-vertical {
  height: 0px;
  opacity: 0;
  visibility: hidden;
  transition: height 0.5s ease;
}

.rounded {
  border-radius: 0.25rem;
}

.title-card-box {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  text-align: left;
  font-weight: 700;
  height: 25px; /// add new
  color: #303030;
  transition: 0.3s ease;
}

.bg-gray-100 {
  background-color: rgb(243, 244, 246) !important;
}

.text-box-inline {
  text-align: left;
  font-size: 11px;
  color: #808284;
}
/////////////////////////////////////////////////custom popover//////////////////////////////////////////
.bg-blur {
  position: absolute;
  bottom: 0;
  display: flex;
  right: 0;
  left: 0;
  justify-content: center;
  height: 40px;
  opacity: 0.6;
  backdrop-filter: blur(20px);
}
.btn-space-blur {
  position: absolute;
  bottom: 5px;
  display: flex;
  right: 0;
  left: 0;
  justify-content: center;
  color: #222;
}

.text-preview {
  font-weight: bold;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-item-preview {
  width: 100%; //ORIGIN 250PX
  height: 250px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.image-marker__image {
  margin: 0 auto;
  width: 100%;
  // height: 720px;
}

.img-preview .image-marker__image {
  margin: 0 auto;
  width: 100%;
  // height: 720px;
}

.slide-marker-image {
  padding: 0 15px;
}
/////////////////////////////////////////////////custom popover//////////////////////////////////////////


.overflow-productlist-idea {
  height: 330px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 5px;
}

.overflow-productlist-idea::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.overflow-productlist-idea::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eeeeee;
}

/* Handle */
.overflow-productlist-idea::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}

/* Handle on hover */
.overflow-productlist-idea::-webkit-scrollbar-thumb:hover {
  background: gray;
}