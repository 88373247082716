////////////////// layout&menu /////////////////

.ant-layout-content {
  margin: 64px 0px 0px 0px !important;
}

.ant-layout-sider-children {
  background: transparent !important;
}
header.ant-layout-header {
  background-color: gray !important;
}

.left-header {
  border-radius: 0px 20px 0px 0px;
  height: 64px;
  background: #fff !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: unset !important;
}
/* .ant-layout-sider-children {
    background: #fff !important;
  } */
.ant-layout-sider-collapsed .ant-menu-item {
  margin-left: 0px !important;
}
.ant-layout-sider-collapsed .ant-menu-submenu {
  margin-left: 0px !important;
}

.ant-layout-sider {
  background: white !important;
  border-radius: 0px 30px 30px 0px !important;
  box-shadow: 0 6px 6px -4px #cacaca;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #fff !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-image: linear-gradient(to right, #ff6b0b, #ffc627);
  box-shadow: 0 6px 6px -4px #cacaca;
  border-radius: 8px;
  margin-left: 14px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item {
  margin-left: 14px;
  border-radius: 8px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 85% !important;
}

// .ant-menu-dark .ant-menu-item,
// .ant-menu-dark .ant-menu-item-group-title,
// .ant-menu-dark .ant-menu-item > a,
// .ant-menu-dark .ant-menu-item > span > a {
//   color: black !important;
// }

// .ant-menu.ant-menu-dark,
// .ant-menu-dark .ant-menu-sub,
// .ant-menu.ant-menu-dark .ant-menu-sub {
//   color: black !important;
// }

// .ant-menu-item:hover span {
//   color: $primay_color !important;
// }

.ant-menu-item:hover span {
  color: $primay_color;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: $primay_color !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: white !important;
  // border-radius: 8px !important;
}
// .ant-menu-item.ant-menu-item-selected:hover span {
//   color: white !important;
// }

// li.ant-menu-item{
//   // border-radius: 8px !important;
// }

////////////////////////////////***Item menu Main SideBar///////////////
// .ant-menu-item,
// .ant-menu-submenu {
//   margin-left: 15px !important;
// }

// .ant-menu-item {
//   padding-left: 12px !important ;
// }

// li.ant-menu-item.ant-menu-item-selected.sidebar-menu-main {
//   margin-left: 12px;
//   padding-left: 13px !important;
// }

.ant-menu-title-content .sidebar-item-menu {
  color: black;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .ant-menu-title-content
  .sidebar-item-menu {
  color: white;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-active.ant-menu-item-selected
  .ant-menu-title-content
  .sidebar-item-menu:hover {
  color: white;
}
////////////////////////////////***Item menu Main SideBar///////////////

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .icons {
  filter: brightness(100);
}

.ant-menu-title-content {
  margin-left: 4px !important;
}

.ant-layout-sider-collapsed .ant-menu-item .icons {
  margin-left: 6px !important;
}

.ant-layout-sider-collapsed .logo-visible {
  display: none !important;
  transition: all 0.1ms;
}
.ant-layout-sider-collapsed .icons-collapsed {
  width: 100% !important;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: unset !important;
}
.ant-layout-sider {
  height: 100vh !important;
  position: fixed !important;
  z-index: 5;
}
///////////////////////Card////////////////////////

.card-round.ant-card.ant-card-bordered {
  border-radius: 8px;
}

ul.ant-card-actions {
  background: #f9f9f9 !important;
  border-radius: 0px 0px 8px 8px !important;
  border: 0px solid white;
  padding: 0px 24px 0px 24px;
}
.ant-card-extra {
  margin-left: unset !important;
  font-weight: 600 !important;
}
.no-shadow > .ant-card {
  box-shadow: unset !important;
}
.bg-grey > .ant-card-head {
  background: #f9f9f9;
}
.bg-grey > .ant-avatar {
  background: #f9f9f9;
}
.bg-grey > .ant-card-head {
  background: #f9f9f9;
}
.card-bg-grey > .ant-card-head {
  background: #fcfcfc !important;
}
.card-bg-grey > .ant-card-body {
  background-color: #fcfcfc !important;
  border-top: 1px solid #f0f0f0;
}

.card-form.ant-card.ant-card-bordered {
  background: white;
  border-radius: 8px !important;
}

.card-media.ant-card.ant-card-bordered {
  background: white;
  border-radius: 0px 8px 8px 8px !important;
}

.card-out-padding > .ant-card-body {
  padding: 0px 0px !important;

  // background: blue !important;
}

.card-out-padding > .ant-card.ant-card-bordered {
  border-radius: 8px 8px 8px 8px !important;
}

.ant-card.card-group {
  height: 120%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 6px 6px -4px #cacaca;
  border-radius: 0px 8px 8px 8px !important;
}
.colors-card > .ant-card-head {
  background: #f9f9f9;
}
.colors-card.ant-card-bordered {
  border-radius: 8px 0px 0px 8px !important;
  border-top: 0px solid blue;
  border-left: 0px solid blue;
  border-bottom: 0px solid blue;
  border-right: 1px solid #f0f0f0;
}
.colors-card .ant-card-extra {
  padding: 12px 0;
}

.card-for-tabs.ant-card {
  border-radius: 0px 8px 8px 8px !important;
}

///////////////////////Button////////////////////////
// .ant-btn {
//   padding: 8px 30px 8px 30px;
// }
.ant-btn-primary {
  box-shadow: 0 6px 6px -4px #cacaca;
}
.ant-btn:hover {
  box-shadow: unset !important;
}

// .ant-btn.ant-btn-default {
//   border: 0px solid white !important;
// }

// .ant-btn.ant-btn-default {
//   width: 130px !important;
// }

.btn-save.ant-btn {
  background: #6dbb1a;
  color: white;
  width: 130px;
}

.btn-save.ant-btn:hover {
  background: greenyellow;
  color: white;
}
.btn-search {
  text-shadow: unset !important;
  background: unset !important;
  color: $primay_color !important;
  border: 1px solid $primay_color !important;
}
.btn-search:hover {
  background: $primay_color !important;
  color: white !important;
  border: 1px solid $primay_color;
}
.ant-btn {
  border-radius: 4px !important ;
  height: 40px !important;
}

.ant-btn:hover.clear {
  color: #808284 !important;
}
.ant-btn:hover.clear {
  color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.ant-btn.media-container {
  padding: unset !important;
  height: 30px !important;
  width: 100%;
  background: #808284 !important;
  color: white !important;
}
.blackHome > .ant-btn {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.blackHome > .ant-btn-primary,
.ant-btn-primary::after,
.blackHome > .ant-btn-primary:focus,
.blackHome > .ant-btn-primary:hover {
  background: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0);
  text-shadow: none !important;
  box-shadow: none !important;
}

.buttonSearchMedia .ant-btn {
  padding: 8px 15px 8px 15px !important;
  border-radius: 0px 4px 4px 0px !important;
}

///////////////////////Input////////////////////////
.ant-input {
  border-radius: 4px !important;
  min-height: 40px;
}

.ant-input.search {
  border: unset !important;
}

.ant-input.input-role {
  width: 87%;
}

.ant-input.media-container {
  height: 30px !important;
}
.ant-input-number {
  width: 100% !important;
  border-radius: 4px !important;
  height: 40px !important;
  padding: 4px 0px !important;
}

.ant-input-lg {
  font-size: 14px !important;
}

.searchMedia .ant-input {
  border-radius: 4px 0px 0px 4px !important;
}

///////////////////////Select////////////////////////
.ant-select-selection-item .an {
  display: flex;
  align-items: center;
}
.ant-select-selector {
  width: 100%;
  border-radius: 4px !important;
  min-height: 40px !important;
}

.pagi .ant-select-selector {
  height: 32px !important;
}
.ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}
.ant-select {
  width: 100%;
}

tr [data-row-key="1"] .ant-table-row.ant-table-row-level-0 {
  background: black !important;
}

.ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-select-multiple .ant-select-selector {
  padding: 1px 10px !important;
}
///////////////////////Form////////////////////////

.ant-form-item {
  margin-bottom: unset !important;
}
.ant-input-affix-wrapper {
  padding: 0px 11px !important;
  border-radius: 4px !important;
  // height: 40px !important;
}

//แก้ได้ส่วนนี้
.ant-form-item-control-input-content > .ant-input-affix-wrapper > .ant-input {
  height: 38px !important;
}

///////////////////////DatePiker////////////////////////
.ant-picker {
  width: 100%;
  border-radius: 4px !important;
  height: 40px !important;
}

///////////////////////RadioGroup////////////////////////

label.ant-radio-button-wrapper {
  height: 50px !important;
  // height: 50px !important;
  padding: 10px !important;

  width: 130px !important;
}
label.ant-radio-button-wrapper span {
  display: flex !important;
  justify-content: center !important;
}
label.ant-radio-button-wrapper:first-child {
  border-radius: 8px 0px 0px 0px;
}

label.ant-radio-button-wrapper:last-child {
  border-radius: 0px 8px 0px 0px;
}

.first-child span.ant-radio-button:first-child.ant-radio-button-checked {
  background: $primay_color;
  border-radius: 8px 0px 0px 0px !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: white !important;
}
.ant-radio-button-checked {
  background: $primay_color;
}
.last-child span.ant-radio-button.ant-radio-button-checked {
  background: $primay_color;
  border-radius: 0px 8px 0px 0px !important;
}

.button-child span {
  color: white !important;
}

// .ant-radio-button-wrapper-checked span {
//   color: white !important;
// }
///////////////////////Table////////////////////////
.ant-table-content {
  overflow-x: auto;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: unset !important;
}
tr th.ant-table-cell {
  background: white !important;
  padding: 2px;
}
.ant-table-thead > tr > th {
  padding: 15px !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0 !important;
}

.ant-table-tbody > tr > td {
  padding: 10px 20px !important;
}
// .ant-table,
// .ant-table:hover {
//   border-radius: 8px !important;
// }
///////////////////////Table////////////////////////

.ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0px 8px 0px 8px !important;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  min-height: 15rem;
}

///////////////////////Tabs////////////////////////
.tab-mainmenu {
  .ant-tabs-nav {
    margin: unset !important;
  }
  .ant-tabs-tab {
    width: 130px;
    background: white !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: unset !important;
  }

  .ant-tabs-tab {
    display: flex;
    justify-content: center !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: $primay_color !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
  }
  .ant-tabs-tab:first-child {
    border-radius: 8px 0px 0px 0px !important;
  }

  .ant-tabs-tab.child {
    border-radius: 8px 0px 0px 0px !important;
  }

  .ant-tabs-tab:nth-last-child(2) {
    border-radius: 0px 8px 0px 0px !important;
  }

  .ant-tabs-nav-list {
    margin-left: 1px !important;
  }

  .ant-transfer-list {
    width: 44rem !important;
  }
}

.tab-basic {
  .ant-tabs-nav {
    margin: unset !important;
  }
  .ant-tabs-tab {
    width: 160px;
    background: transparent !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: unset !important;
  }

  .ant-tabs-tab {
    display: flex;
    justify-content: center !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: transparent !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000d9 !important;
  }
  .ant-tabs-content-holder {
    padding: 24px 24px !important;
  }
  // .ant-tabs-tab:first-child {
  //   border-radius: 8px 0px 0px 0px !important;
  // }

  // .ant-tabs-tab.child {
  //   border-radius: 8px 0px 0px 0px !important;
  // }

  // .ant-tabs-tab:nth-last-child(2) {
  //   border-radius: 0px 8px 0px 0px !important;
  // }

  // .ant-tabs-nav-list {
  //   margin-left: 1px !important;
  // }

  // .ant-transfer-list {
  //   width: 44rem !important;
  // }
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 0 !important;
}

.border-tab-mainmenu {
  border-top: 1px solid rgba(243, 243, 243, 0.418) !important;
}

///////////////////////Modal////////////////////////

.boder-radius > .ant-modal-content {
  overflow: auto;
  border-radius: 15px;
}

///////////////////////Transfer////////////////////
.ant-transfer-list {
  width: 44rem !important;
  color: #000000;
}

span.ant-transfer-list-header-dropdown {
  display: none;
}

.ant-transfer-list-header {
  height: 60px !important;
}

.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 20px !important;
}

/////////////////////component in user page/////////////////////
.ant-input.input-role {
  width: 87%;
}

.ant-btn.bt-uploadimg {
  width: 100%;
}

.ant-btn.bt-delPhoto {
  width: 100%;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 80px !important;
}

/////////////////////Dropdown/////////////////////
.ant-dropdown-open:hover div {
  color: white !important;
}

/////////////////////Badge/////////////////////

.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  top: 2px !important;
  right: 5px !important;
}

.ant-badge-count {
  width: 15px !important;
  min-width: unset !important;
  height: 15px !important;
  font-size: 10px !important;
  line-height: 15px !important;
  padding: 0px 5px !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  height: 100vh !important;
  max-height: unset !important;
}

/////////////////////Upload fileList/////////////////////

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 44px !important;
  padding: unset !important;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  .ant-upload-list-item-card-actions
  .ant-btn {
  width: 41px !important;
  height: 41px !important;
}

.ant-upload-list-item-card-actions .ant-btn {
  padding: 0px !important;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 40px !important;
}
.ant-upload.ant-upload-select.ant-upload-select-picture {
  width: 100% !important;
}

////////////list project menu modal iamge//////////////////////////////////
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  width: auto;
}

li.ant-menu-item.ant-menu-item-selected.tw-b.c-grey.d-flex.justify-between:hover {
  color: $primay_color;
}

.ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected.tw-b.c-grey.d-flex.justify-between {
  // background-color: #fafafa;
  color: white;
  background-color: $primay_color;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  width: auto;
}

.ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected.tw-b.c-grey.d-flex.justify-between.ant-menu-item:hover
  span {
  color: white;
}

li.ant-menu-item.ant-menu-item-selected.tw-b.c-grey.d-flex.justify-between::after {
  border-right: 3px solid red;
  border-radius: 0px;
}

// .ant-menu-item.tw-b.c-grey.d-flex.justify-between{
//   padding-left: 25px !important ;
// }
////////////list project menu modal iamge//////////////////////////////////

//////////////// popover /////////////////

// .ant-popover-message-title {
//   padding: unset !important;
// }

////////////preview
.modal-preview .ant-modal-body {
  background: #f0f2f5;
}

//////////////// carosel /////////////////

.ant-carousel .slick-dots li.slick-active button {
  background: #ff9021 !important;
}

.ant-carousel .slick-dots li button {
  background: #abaaac !important;
}

.ant-input-number-group-wrapper {
  width: 100%;
}
