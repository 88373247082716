.main-card-category {
  margin: 10px 0px;
}
.main-card-category-item {
  margin-bottom: 5px;
}
.collapse-card-category {
  width: 100%;
  display: flex;
}
.drag-card-category {
  border: 1px solid #d9d9d9;
  width: 66px;
  height: 66px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  text-align: center;
  cursor: all-scroll;
}
.main-card-category .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center !important;
}
